<template>
    <template v-if="src">
        <NuxtPicture
            :src="src"
            :width="maxWidth"
            :height="maxHeight"
            :sizes="sizes"
            :placeholder="placeholder ? '/no_image2.svg' : undefined"
            :class="[{ 'opacity-10': !imageLoaded }]"
            class="transition-opacity duration-300"
            @load="imageLoaded = true"
        />
    </template>
    <template v-else-if="(!imageLoaded && !src) || placeholder">
        <div class="flex items-center justify-center w-full h-full">
            <NuxtImg
                src="/no_image2.svg"
                :width="maxWidth"
                :height="maxHeight"
                class="w-2/3 h-2/3 opacity-10 object-contain"
            />
        </div>
    </template>
</template>

<script setup lang="ts">
defineProps<{
    src?: string
    placeholder?: boolean
}>() // Define maximum dimensions for image optimization
const maxWidth = 1024 // Maximum width you expect the image to be displayed
const maxHeight = 1024 // Maximum height you expect the image to be displayed

// Responsive sizes configuration
const sizes = 'sm:100vw md:50vw lg:33vw'

const imageLoaded = ref(false)
</script>
